import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './components/reset.css'
import ViewUI from 'view-design';
import BaiduMap from 'vue-baidu-map'
import request from '@/components/axios'

Vue.prototype.request = request

// import style
import 'view-design/dist/styles/iview.css';


Vue.use(ViewUI);
Vue.use(BaiduMap, {
    ak: '2HQQFbGITRiGQEG2eotzhthfLo3MPVbO'
})

Vue.config.productionTip = false

new Vue({
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
