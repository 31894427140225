import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
        }
    },
    {
        path: '/search',
        name: 'search',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/search.vue')
        }
    },
    {
        path: '/page0',
        name: 'page0',
        component: function () {
            // return import(/* webpackChunkName: "about" */ '../views/0/page0')
            return import(/* webpackChunkName: "about" */ '../views/0/0-0/ListView0-0')
        }
    },
    {
        path: '/page1',
        name: 'page1',
        component: function () {
            // return import(/* webpackChunkName: "about" */ '../views/1/page1')
            return import(/* webpackChunkName: "about" */ '../views/1/1-0/ListView1-0')
        }
    },
    {
        path: '/page2',
        name: 'page2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/2/page2')
        }
    },
    {
        path: '/page3',
        name: 'page3',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/page3')
        }
    },

    {
        path: '/list0-0',
        name: 'list0-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-0/ListView0-0')
        }
    },
    {
        path: '/list0-1',
        name: 'list0-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-1/ListView0-1')
        }
    },
    {
        path: '/list0-2',
        name: 'list0-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-2/ListView0-2')
        }
    },
    {
        path: '/list0-3',
        name: 'list0-3',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-3/ListView0-3')
        }
    },
    {
        path: '/list0-4',
        name: 'list0-4',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-4/ListView0-4')
        }
    },
    {
        path: '/list0-5',
        name: 'list0-5',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-5/ListView0-5')
        }
    },
    {
        path: '/list0-6',
        name: 'list0-6',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-6/ListView0-6')
        }
    },
    {
        path: '/list1-0',
        name: 'list1-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-0/ListView1-0')
        }
    },
    {
        path: '/list1-1',
        name: 'list1-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-1/ListView1-1')
        }
    },
    {
        path: '/list1-2',
        name: 'list1-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-2/ListView1-2')
        }
    },
    {
        path: '/list2-0',
        name: 'list2-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/2/2-0/ListView2-0')
        }
    },
    {
        path: '/list2-1',
        name: 'list2-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/2/2-1/ListView2-1')
        }
    },
    {
        path: '/list3-0',
        name: 'list3-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-0/ListView3-0')
        }
    },
    {
        path: '/list3-1',
        name: 'list3-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-1/ListView3-1')
        }
    },
    {
        path: '/list3-2',
        name: 'list3-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-2/ListView3-2')
        }
    },
    {
        path: '/list3-3',
        name: 'list3-3',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-3/ListView3-3')
        }
    },
    {
        path: '/list3-4',
        name: 'list3-4',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-4/ListView3-4')
        }
    },
    {
        path: '/info0-0',
        name: 'info0-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-0/InfoView0-0')
        }
    },
    {
        path: '/info0-1',
        name: 'info0-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-1/InfoView0-1')
        }
    },
    {
        path: '/info0-2',
        name: 'info0-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-2/InfoView0-2')
        }
    },
    {
        path: '/info0-3',
        name: 'info0-3',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-3/InfoView0-3')
        }
    },
    {
        path: '/info0-4',
        name: 'info0-4',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-4/InfoView0-4')
        }
    },
    {
        path: '/info0-5',
        name: 'info0-5',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-5/InfoView0-5')
        }
    },
    {
        path: '/info0-6',
        name: 'info0-6',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/0/0-6/InfoView0-6')
        }
    },
    {
        path: '/info1-0',
        name: 'info1-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-0/InfoView1-0')
        }
    },
    {
        path: '/info1-1',
        name: 'info1-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-1/InfoView1-1')
        }
    },
    {
        path: '/info1-2',
        name: 'info1-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/1-2/InfoView1-2')
        }
    },
    {
        path: '/InfoView1',
        name: 'InfoView1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/1/InfoView1')
        }
    },






    {
        path: '/info2-0',
        name: 'info2-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/2/2-0/InfoView2-0')
        }
    },
    {
        path: '/info2-1',
        name: 'info2-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/2/2-1/InfoView2-1')
        }
    },
    {
        path: '/info3-0',
        name: 'info3-0',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-0/InfoView3-0')
        }
    },
    {
        path: '/info3-1',
        name: 'info3-1',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-1/InfoView3-1')
        }
    },
    {
        path: '/info3-2',
        name: 'info3-2',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-2/InfoView3-2')
        }
    },
    {
        path: '/info3-3',
        name: 'info3-3',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-3/InfoView3-3')
        }
    },
    {
        path: '/info3-4',
        name: 'info3-4',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/3/3-4/InfoView3-4')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach((to, from, next) => {
  window.scroll(0,0)
})

export default router
