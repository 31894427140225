import axios from 'axios'

const BladeAuth = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
const Authorization =
    'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJyb2xlX25hbWUiOiJpbmZvQWRtaW4iLCJwb3N0X2lkIjoiMTEyMzU5ODgxNzczODY3NTIwOCIsInVzZXJfaWQiOiIxNTU4NzI0MzY0NzU5NzgxMzc4Iiwicm9sZV9pZCI6IjE1NTg3MjM1MTQ5NjQ0MzA4NDkiLCJ1c2VyX25hbWUiOiJpbmZvX2FkbWluIiwibmlja19uYW1lIjoi5L-h5oGv566h55CG5ZGYIiwiZGV0YWlsIjp7InR5cGUiOiJ3ZWIifSwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMTIzNTk4ODEzNzM4Njc1MjAxIiwiYWNjb3VudCI6ImluZm9fYWRtaW4iLCJjbGllbnRfaWQiOiJzYWJlciIsImV4cCI6MTY2NTE2MDcwMCwibmJmIjoxNjY1MTU3MTAwfQ.hOr2iaz7iuMwCWLck3udJiWKESovPUsnDLI5DsLayeRTh8GlxzrZfsfIPqYQOyMIl-mNmOl4ABfSgAbU3wsi8w'


// const baseurl = 'https://cxx.wdsj2010.com/api/'
const baseurl = '/api/'
const http = axios.create({
    baseURL: baseurl,
    timeout: 60000 // request timeout
})


// request interceptor
http.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        return Promise.reject(err)
    }
)

// response interceptor
http.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        console.log(error)

    }
)


export default {
    name: 'request',
    get: function (url, data, callback) {
        http({
            url: baseurl + url,
            method: "GET",
            data: data,
            params: data,
            headers: {
                'Authorization': Authorization,
                'Blade-Auth': BladeAuth
            },

        }).then(function (res) {
            if (callback != undefined) {
                callback(res);
            }
        })
    },
    post: function (url, data, callback, h) {

        http({
            url: baseurl + url,
            method: "POST",
            data: data,
            params: data,
            headers: {
                'content-type': h == undefined ? 'application/x-www-form-urlencoded' : h,
                'Authorization': Authorization,
                'Blade-Auth': BladeAuth
            },
        }).then(function (res) {
            if (callback != undefined) {
                callback(res);
            }
        })
    },
    gethtml: function (html = '') {
        var s = ''
        s = html.replace(/<\/?[^>]+>/g, '');
        s = s.replace(/&nbsp;/g, '');
        return s
    }

}
